<template>
  <div class="home">
    <el-carousel
      class="ban"
      trigger="click"
      height="824px"
      :interval="8000"
      arrow="always"
    >
      <el-carousel-item v-for="(item, index) in ban" :key="index">
        <div class="ban_item">
          <div class="ban_item_text" :class="{ ban_item_links: item.isClass }">
            <h3>{{ item.title }}</h3>
            <p>{{ item.des }}</p>
            <template v-for="(link, i) in item.links">
              <span :key="i" class="">{{ link }}</span>
            </template>
          </div>
          <el-image :src="item.src" fit="cover">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
    <swiper class="banner" :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item, index) in ban" :key="index">
        <div class="ban_item">
          <div class="ban_item_text" :class="{ ban_item_links: item.isClass }">
            <h3>{{ item.title }}</h3>
            <p>{{ item.des }}</p>
          </div>
          <img :src="item.src" alt="" />
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- 特色 -->
    <div class="features-bar">
      <div class="features-item">
        <span class="icon-kehuqunti"></span>
        <h3>客户群体</h3>
        <p>
          依托现有业务经验，以广东省体育局 为辐射点，向全国范围进行客户延伸。
        </p>
      </div>
      <div class="features-item">
        <span class="icon-fuwudingwei"></span>
        <h3>服务定位</h3>
        <p>
          专注于体育行业信息化和信息咨询服务，打造面向客户统一运营和面向产品专业运营的业务和运营支撑体系。
        </p>
      </div>
      <div class="features-item">
        <span class="icon-zhanlve"></span>
        <h3>战略思想</h3>
        <p>
          坚持厚积薄发的可持续发展战略思想，不断积累和丰富实战经验，壮大自身，服务客户。
        </p>
      </div>
      <div class="features-item">
        <span class="icon-fuwujingshen"></span>
        <h3>服务精神</h3>
        <p>
          始于热爱、止于至善，保持专业、敬业、宏业的核心价值观念，为客户提供高质服务。
        </p>
      </div>
    </div>
    <!-- 我们的服务 -->
    <div class="service-bar" :class="{ testenter: testShow }" ref="testref">
      <div class="wrapper-content">
        <div class="title">我们的服务</div>
        <div class="service">
          <div class="service-item">
            <div class="service-imgbox">
              <el-image
                class="service-img"
                fit="cover"
                :src="require('@/assets/home/service (2).jpg')"
              ></el-image>
            </div>
            <div class="service-text">
              <h3>服务架构</h3>
              <p>
                遵循体育行业标准，依托信息化，实现智慧全民健身、智慧竞技体育、智慧体育产业大数据综合服务平台。
              </p>
            </div>
          </div>
          <div class="service-item">
            <div class="service-imgbox">
              <el-image
                class="service-img"
                fit="cover"
                :src="require('@/assets/home/service (1).jpg')"
              ></el-image>
            </div>
            <div class="service-text">
              <h3>用户群体</h3>
              <p>
                <span>个体：</span>教练、裁判、体育爱好者等个人用户；<br />
                <span>组织：</span
                >体育行政部门、体育社团组织、企业和赛事组织等单位用户。
              </p>
            </div>
          </div>
          <div class="service-item">
            <div class="service-imgbox">
              <el-image
                class="service-img"
                fit="cover"
                :src="require('@/assets/home/service (5).jpg')"
              ></el-image>
            </div>
            <div class="service-text">
              <h3>个性化服务</h3>
              <p>
                通过对用户的属性信息和发布数据，了解和定位客户需求，为平台优化业务流程提供数据依托。全面分析用户的业务行为偏好，提供个性化专业服务。
              </p>
            </div>
          </div>
          <div class="service-item">
            <div class="service-imgbox">
              <el-image
                class="service-img"
                fit="cover"
                :src="require('@/assets/home/service (6).jpg')"
              ></el-image>
            </div>
            <div class="service-text">
              <h3>设计原则</h3>
              <p>
                <span>先进性：</span
                >系统应具有先进性和成熟性，具备较长的使用周期，避免业务功能模块的过早淘汰。<br />
                <span>方便性：</span
                >对业务功能模块按需求进行两端建设，通过线上+移动的办理模式实现训练竞赛、办公管理、场馆服务自动化、智能化。<br />
                <span>可靠性：</span
                >保证数据管理后台与官网的安全稳定运行，提高体育信息化服务水平与质量，营造环境安全、体验舒适、相应及时的信息平台。<br />
                <span>兼容性：</span
                >采用系统灵活集成技术，所有子业务平台具有良好的开放性和扩展性，并符合业界相关系统互联方面的国际标准和协议。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiperBox">
        <swiper class="banner" :options="serviceOption" ref="serviceSwiper">
          <swiper-slide v-for="(item, index) in service" :key="index">
            <div class="imgBox">
              <img :src="item.src" alt="" />
              <div class="imgtext">
                <h3>{{ item.title }}</h3>
                <p class="ql-editor" v-html="item.content"></p>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution-bar" :class="{ testenter: testShow2 }" ref="testref2">
      <div class="wrapper-content">
        <div class="des-bar">
          <h3>产品中心</h3>
          <p>
            智慧体育综合管理平台由松冬体育自主设计研发，将数字化技术与体育信息发布、管理进行深度融合，实现对客户内部、<br />
            赛事活动培训、会员等核心业务的一站式管理，打造纵横双向立体管理的数字孪生系统。
          </p>
        </div>

        <div class="solution-list">
          <div class="solution-item">
            <div class="solution-item-icon bg145">
              <el-image
                class="solution-img"
                :src="require('@/assets/home/solution (1).png')"
              ></el-image>
              <h4>全民健身服务平台</h4>
            </div>
            <div class="solution-item-text">
              <h4>全民健身服务平台</h4>
              <p>
                提供多样式的官网模板由用户自行选择，可自定义导航栏目、发布宣传信息。
              </p>
              <el-button type="primary" class="more" @click="moreClick(1)"
                >查看更多</el-button
              >
            </div>
          </div>
          <div class="solution-item">
            <div class="solution-item-icon bg26">
              <el-image
                class="solution-img"
                :src="require('@/assets/home/solution (2).png')"
              ></el-image>
              <h4>竞技体育服务平台</h4>
            </div>
            <div class="solution-item-text">
              <h4>竞技体育服务平台</h4>
              <p>
                按年度对运动员、教练员、裁判员、工作人员等信息进行注册、申报和审核，行程人员信息资料库。
              </p>
              <el-button type="primary" @click="moreClick(2)"
                >查看更多</el-button
              >
            </div>
          </div>
          <div class="solution-item">
            <div class="solution-item-icon bg3">
              <el-image
                class="solution-img"
                :src="require('@/assets/home/solution (3).png')"
              ></el-image>
              <h4>体育产业服务平台</h4>
            </div>
            <div class="solution-item-text">
              <h4>体育产业服务平台</h4>
              <p>
                建立省级名录库系统，面向省市层级，实现信息导入导出、系统去重、省局初筛、地市核查与上报、省局审批、信息查询、统计分类与打印等功能。
              </p>
              <el-button type="primary" @click="moreClick(3)"
                >查看更多</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="solution_phone">
        <div class="solution_phone_one">
          <div class="solution_phone_title">
            <img src="../assets/home/solution (1).png" alt="" />全民健身服务平台
          </div>
          <div class="solution_phone_cen">
            提供多样式的官网模板由用户自行选择，可自定义导航栏目、发布宣传信息。
          </div>
          <div class="solution_phone_but" @click="moreClick(1)">查看更多</div>
        </div>
        <div class="solution_phone_one solution_phone_two">
          <div class="solution_phone_title">
            <img src="../assets/home/solution (2).png" alt="" />竞技体育服务平台
          </div>
          <div class="solution_phone_cen">
            按年度对运动员、教练员、裁判员、工作人员等信息进行注册、申报和审核，行程人员信息资料库。
          </div>
          <div class="solution_phone_but" @click="moreClick(2)">查看更多</div>
        </div>
        <div class="solution_phone_one solution_phone_tre">
          <div class="solution_phone_title">
            <img src="../assets/home/solution (3).png" alt="" />体育产业服务平台
          </div>
          <div class="solution_phone_cen">
            建立省级名录库系统，面向省市层级，实现信息导入导出、系统去重、省局初筛、地市核查与上报、省局审批、信息查询、统计分类与打印等功能。
          </div>
          <div class="solution_phone_but" @click="moreClick(3)">查看更多</div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="partners-bar" :class="{ testenter: testShow3 }" ref="testref3">
      <div class="wrapper-content">
        <div class="title">合作伙伴</div>
        <div id="pcPartners" class="partners">
          <ul>
            <li v-for="(item, index) in partnerList" :key="index">
              <el-image
                class="p-img"
                fit="contain"
                :src="loadUrl(item.logo)"
              ></el-image>
              <div class="navs">
                <span @click="linkClick(item.id)">进入官网</span>
                <span @click="goDetail(`/partners-apply/${item.id}`)"
                  >申请会员</span
                >
                <!-- <span>进入赛事</span> -->
              </div>
              <!-- <span class="zhName">进入赛事</span> -->
            </li>
          </ul>
          <el-button class="btn-more" @click="mores('/partners')"
            >查看更多</el-button
          >
        </div>
        <div id="phonePartners" class="partners">
          <ul>
            <li v-for="(item, index) in partnerList.slice(0, 3)" :key="index">
              <el-image
                class="p-img"
                fit="contain"
                :src="loadUrl(item.logo)"
              ></el-image>
              <div class="phoneNavs">
                <span @click="linkClick(item.id)">进入官网</span>
                <span @click="goDetail(`/partners-apply/${item.id}`)"
                  >申请会员</span
                >
                <!-- <span>进入赛事</span> -->
              </div>
              <!-- <span class="zhName">进入赛事</span> -->
            </li>
          </ul>
          <el-button class="btn-more" @click="mores('/partners')"
            >查看更多</el-button
          >
        </div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div class="about-bar" :class="{ testenter: testShow4 }" ref="testref4">
      <el-row class="wrapper-content flex">
        <el-col :lg="12" :sm="24" :xs="24">
          <el-image
            class="about-img"
            :src="require('@/assets/home/about.jpg')"
          ></el-image
        ></el-col>
        <el-col :lg="12" :sm="24" :xs="24" class="about-text">
          <div class="title">关于我们</div>
          <el-image
            class="about-img-two"
            :src="require('@/assets/home/about.jpg')"
          ></el-image>
          <p>
            智慧体育综合管理平台由松冬体育自主设计研发，将数字化技术与体育信息发布、管理进行深度融合，实现对客户内部、赛事活动培训、会员等核心业务的一站式管理，打造纵横双向立体管理的数字孪生系统。
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { getTenantInfo, getTenant } from "@/api/template";
import { getOrganizeUnitList } from "@/api/partners";

export default {
  name: "index",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      nowIndex: 0,
      ban: [
        {
          title: "爱体育智慧化管理平台",
          des: "专业 • 敬业 • 宏业",
          links: [],
          src: require("../assets/home/banner1.jpg"),
          isClass: false,
        },
        {
          title: "个性化定制服务",
          des: "围绕平台现有的常态化服务板块，为不同客户群体根据不同需求提供个性化定制服务",
          links: ["官方网站", "赛事活动", "会员体系", "场地服务", "数据报表"],
          src: require("../assets/home/banner2.jpg"),
          isClass: true,
        },
      ],
      queryParams: {
        current: 1,
        size: 10,
        item: "",
        title: "",
        type: "",
      },
      partnerList: [],
      swiperOption: {
        autoplay: true,
        speed: 300,
        loop: true,
        pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
        },
      },
      serviceOption: {
        autoplay: true,
        speed: 300,
        loop: true,
        pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
        },
      },
      service: [
        {
          src: require("@/assets/home/service (2).jpg"),
          title: "服务架构",
          content:
            "遵循体育行业标准，依托信息化，实现智慧全民健身、智慧竞技体育、智慧体育产业大数据综合服务平台。",
        },
        {
          src: require("@/assets/home/service (1).jpg"),
          title: "需求迭代",
          content:
            "个体：教练、裁判、体育爱好者等个人用户；<br/>组织：体育行政部门、体育社团组织、企业和赛事组织等单位用户。",
        },
        {
          src: require("@/assets/home/service (5).jpg"),
          title: "个性化服务",
          content:
            "通过对用户的属性信息和发布数据，了解和定位客户需求，为平台优化业务流程提供数据依托。全面分析用户的业务行为偏好，提供个性化专业服务。",
        },
        {
          src: require("@/assets/home/service (6).jpg"),
          title: "设计原则",
          content:
            "先进性：系统应具有先进性和成熟性，具备较长的使用周期，避免业务功能模块的过早淘汰。<br/>方便性：对业务功能模块按需求进行两端建设，通过线上+移动的办理模式实现训练竞赛、办公管理、场馆服务自动化、智能化。<br/>可靠性：保证数据管理后台与官网的安全稳定运行，提高体育信息化服务水平与质量，营造环境安全、体验舒适、相应及时的信息平台。<br/>兼容性：采用系统灵活集成技术，所有子业务平台具有良好的开放性和扩展性，并符合业界相关系统互联方面的国际标准和协议。",
        },
      ],
      currentScroll: 0,
      testShow: false,
      testShow2: false,
      testShow3: false,
      testShow4: false,
    };
  },
  mounted() {
    let _token = JSON.stringify(this.$route.query.taken);
    let domainTid = JSON.stringify(this.$route.query.domainTid)
    let _tenantId = domainTid ? domainTid : window.localStorage.getItem('domainTid')
    if (_token) {
      window.localStorage.setItem("token", _token);
    }
    if (_tenantId) {
      window.localStorage.setItem("domainTid", _tenantId);
    }
    this.getGetTenantInfo();
    // this.getPartner();
    window.addEventListener("scroll", this.handleScroll, true); //？监听浏览器滚动条滚动
    this.$store.dispatch("saveHeadActive", "/index");
  },
  methods: {
    handleScroll() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll > this.$refs.testref.offsetTop - 550) {
        this.testShow = true;
      }
      if (this.currentScroll > this.$refs.testref2.offsetTop - 550) {
        this.testShow2 = true;
      }
      if (this.currentScroll > this.$refs.testref3.offsetTop - 550) {
        this.testShow3 = true;
      }
      if (this.currentScroll > this.$refs.testref4.offsetTop - 550) {
        this.testShow4 = true;
      }
    },
    init() {
      const self = this;
      let sign = self.sign;
      self.$store.commit("showLoading");

      getHome({ sign }).then((res) => {
        if (res.code == 200) {
          self.$store.commit("hideLoading");
        } else {
          self.$toast(res.msg);
        }
      });
    },
    // 详情
    goDetail(url) {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$message({
          message: "请先登录",
          type: "warning",
          duration: 1500,
          onClose: () => {
            window.location.href = process.env.VUE_APP_SAAS_URL + "/login";
          },
        });
        return;
      }
      this.$router.push(url);
    },
    // 进入官网
    linkClick(id) {
      localStorage.setItem("domainTid", id);
      getTenant().then((res) => {
        if (res.data) {
          let data = res.data;
          let str = data.websiteLogo
            ? `http://${data.websiteLogo}${
                data.type == 1
                  ? "/#/template-first"
                  : data.type == 2
                  ? "/#/template-second"
                  : data.type == 3
                  ? "/#/template-third"
                  : data.type == 4
                  ? "/#/template-fourth"
                  : data.type == 5
                  ? "/#/template-fifth"
                  : data.type == 6
                  ? "/#/template-sixth"
                  : data.type == 7
                  ? "/#/template-seventh"
                  : data.type == 8
                  ? "/#/template-eighth"
                  : data.type == 9
                  ? "/#/template-ninth"
                  : data.type == 10
                  ? "/#/template-tenth"
                  : data.type == 11
                  ? "/#/template-eleventh"
                  : ""
              }`
            : "";
          window.open(str);
        }
      });
    },

    // 合作伙伴
    getPartner() {
      const self = this;
      self.$store.commit("showLoading");
      getOrganizeUnitList().then((res) => {
        if (res.code == 200) {
          self.partnerList = res.data.slice(0, 8);
          self.$store.commit("hideLoading");
        } else {
          self.$toast(res.msg);
        }
      });
    },
    // 更多
    mores(url) {
      this.$router.push(url);
    },

    getGetTenantInfo() {
      let dname = window.location.host;
      let _tenantId = window.localStorage.getItem("domainTid");
      if (_tenantId) {
        _tenantId = _tenantId.replace(/^"(.*)"$/, "$1");
      } else {
        _tenantId = "";
      }
      getTenantInfo({ code: dname, tid: _tenantId }).then((res) => {
        if (res.data) {
          if (res.data.type == 1) {
            window.location.href = "http://" + `${dname}/#/template-first`;
          } else if (res.data.type == 2) {
            window.location.href = "http://" + `${dname}/#/template-second`;
          } else if (res.data.type == 3) {
            window.location.href = "http://" + `${dname}/#/template-third`;
          } else if (res.data.type == 4) {
            window.location.href = "http://" + `${dname}/#/template-fourth`;
          } else if (res.data.type == 5) {
            window.location.href = "http://" + `${dname}/#/template-fifth`;
          } else if (res.data.type == 6) {
            window.location.href = "http://" + `${dname}/#/template-sixth`;
          } else if (res.data.type == 7) {
            window.location.href = "http://" + `${dname}/#/template-seventh`;
          } else if (res.data.type == 8) {
            if (this.isMobile()) {
              window.location.href = "http://" + `${dname}/#/template-eighthMobile`;
            } else {
              window.location.href = "http://" + `${dname}/#/template-eighth`;
            }
          } else if (res.data.type == 9) {
            window.location.href = "http://" + `${dname}/#/template-ninth`;
          } else if (res.data.type == 10) {
            if (this.isMobile()) {
              window.location.href = "http://" + `${dname}/#/template-tenthMobile`;
            } else {
              window.location.href = "http://" + `${dname}/#/template-tenth`;
            }
          } else if (res.data.type == 11) {
            window.location.href = "http://" + `${dname}/#/template-eleventh`;
          }  else {
            // this.getCompetitionLists();
            // this.getTrainLists();
            this.getPartner();
          }
          window.localStorage.setItem("domainTid", res.data.tenantId);
        } else {
          // this.getCompetitionLists();
          // this.getTrainLists();
          this.getPartner();
        }
      });
    },

    link(type) {
      if (type === "course") {
        this.$router.push("/course");
      }
    },
    moreClick(type) {
      this.$router.push({ path: "/solution", query: { type: type } });
    },
    isMobile() {
      // console.log(navigator.userAgent)
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/home.scss";
</style>
